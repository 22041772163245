import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import './styles.scss'

const CheckList = loadable(() => import('/src/components/CheckList'))
const Section = loadable(() => import('/src/components/Section'))
const SectionHeader = loadable(() => import('/src/components/SectionHeader'))

const CardDetails = () => {
    const section = {
        title: "We don't ask for your card details"
    }

    const checklist = [
        `We'll NEVER ask for your card details`,
        `Direct Debit Guarantee - Cancel your policy ANYTIME`,
        `No up-front payment required`,
        `30-Day Money Back Guarantee`
    ]

    return (
        <Section variant="dark" className="c-card-details">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <SectionHeader data={section} variant="centered" />
                </div>
            </div>

            <div className="c-section__content">
                <div className="row">
                    <div className="col-lg-8">
                        <p>
                            If you decide you want a policy with us, rest
                            assured: you're always in control. We only need your
                            Direct Debit details (account number and sort code)
                            to get your policy up and running. If you decide you
                            don't want your policy anymore, all you need to do
                            is cancel the direct debit. Simple.
                        </p>

                        <CheckList data={checklist} />
                    </div>

                    <div className="col-lg-4 c-card-details__image-column">
                        <StaticImage
                            src="../../images/illustrations/cards.png"
                            alt="Illustration of many with wallet and bank card"
                        />
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default CardDetails
